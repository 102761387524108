<template>
  <sign-page
    title-text="培训与进修管理"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    @dataFileUpdate="dataFileUpdate"
    :data-file-parm="{pDirPath: '/train/'}"
    :tableActions="tableActions"
    :dealFormData="dealFormData"
    table-size="large"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :on-form-change="onFormChange"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  trainRequest as request,
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit || this.$authFunsProxy.applyEdit || this.$authFunsProxy.applyAdd) {
      this.$store.dispatch('loadWorkerList')
    }
  },
  methods: {
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    dealFormData (data) {
      if (data.startDate && data.endDate && this.timeBj(data.startDate, data.endDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '培训结束日期需要大于培训起始日期。'
        })
        throw Error()
      }
      return data
    },
    onFormChange (key, value, data) {
      if (key === 'startDate' || key === 'endDate') {
        if (data.startDate && data.endDate) {
          let day = (new Date(data.endDate) - new Date(data.startDate)) / 1000 / 60 / 60 / 24 + 1
          return {
            dayNum: day
          }
        }
      }
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.workerList,
          label: '培训员工',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '培训主办单位名称',
          key: 'zbdw'
        },
        {
          type: 'select',
          label: '培训类型',
          selectDatas: this.$store.getters.selectDatas['train_type_list'],
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '出国（出境）培训标识',
          selectDatas: ['是', '否'].map(v => {return {key: v, label: v}}),
          key: 'cg',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '教育培训性质',
          selectDatas: this.$store.getters.selectDatas['train_jypx_list'],
          key: 'jypx',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '培训项目名称',
          key: 'pxxm'
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['train_lg_list'],
          label: '培训离岗状态',
          key: 'lg',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '从学单位类别',
          selectDatas: this.$store.getters.selectDatas['train_cxdw_list'],
          key: 'cxdw',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '培训起始日期',
          key: 'startDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '培训结束日期',
          key: 'endDate'
        },
        {
          type: 'input',
          label: '培训天数',
          key: 'dayNum',
          check: {
            rules: {
              type: 'number'
            }
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList () {
      let data = [{
        title: '员工姓名',
        field: 'workerName',
        sort: true
      },
      {
        title: '员工工号',
        field: 'workerCode',
        sort: true
      },
      {
        title: '身份证号',
        field: 'idNo',
        sort: true,
        width: 150
      },
      {
        title: '培训主办单位名称',
        field: 'zbdw',
        sort: true
      },
      {
        title: '培训类型',
        field: 'type',
        sort: true
      },
      {
        title: '出国（出境）培训标识',
        field: 'cg',
        sort: true
      },
      {
        title: '教育培训性质',
        field: 'jypx',
        sort: true
      },
      {
        title: '培训项目名称',
        field: 'pxxm',
        sort: true
      },
      {
        title: '培训离岗状态',
        field: 'lg',
        sort: true
      },
      {
        title: '从学单位类别',
        field: 'cxdw',
        sort: true
      },
      {
        title: '培训起始日期',
        field: 'startDate',
        sort: true, dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-')
        },
        text: (rowData) => {
          return rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-'
        }
      },
      {
        title: '培训结束日期',
        field: 'endDate',
        sort: true, dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-')
        },
        text: (rowData) => {
          return rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-'
        },
      },
      {
        title: '培训天数',
        field: 'dayNum',
        sort: true, dataType: Number, width: 130
      }]
      return data
    }
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
